import RootTableActionMixin from 'GroomyRoot/mixins/TableAction.js'
import { EventBus } from 'EventBus'

export default {
    mixins: [RootTableActionMixin],
    methods: {
        goToNotifyAct(lines) {
            this.sendEvent('TableAction::notifyActs', lines.map(act => act.actes_id))
        },

        goToPayOnline(lines) {
            this.sendEvent('TableAction::payOnline', lines)
        },

        goToPayContract(lines) {
            this.sendEvent('TableAction::goToPayContract', lines[0])
        },

        goToSelectInvoiceToPayAndExec(lines) {
            this.sendEvent('TableAction::selectInvoiceToPayAndExec', lines)
        },

        goToAddPaymentMethod(lines) {
            this.sendEvent('TableAction::addPaymentMethod')
        },

        goToPdfInvoice(lines) {
			const invoices  = lines.map(i => i.invoice)
			EventBus.$emit("TableAction::goToPdfInvoice", invoices)
		},

        goToHideHorse(horses) {
			const horse_ids = this.constructRouteParams(horses, 'horse_id')
			let params = { horse_ids: horse_ids }
			EventBus.$emit('TableAction::goToHideHorse', params)
		},

        goToAckExpedition(lines) {
            EventBus.$emit("TableAction::goToAckExpedition", lines)
        },

        goToConfirmOpen(lines) {
            EventBus.$emit("TableAction::goToConfirmOpen", lines)
        }
    }
}
